/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    background: var(--oksijen-mavi-dark);
    .footer-top {
        padding: 60px 0 30px 0;
        background: var(--oksijen-mavi-light);
        .footer-contact {
            margin-bottom: 30px;
            h3 {
                font-size: 26px;
                line-height: 1;
                font-weight: 700;
                span {
                    color: var(--oksijen-turuncu);
                }
            }
            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                font-family: "Raleway", sans-serif;
            }
        }
        h4 {
            font-size: 16px;
            font-weight: bold;
            position: relative;
            padding-bottom: 12px;
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 20px;
                height: 2px;
                background: var(--oksijen-turuncu);
                bottom: 0;
                left: 0;
            }
        }
        .footer-links {
            margin-bottom: 30px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    &:first-child {
                        padding-top: 0;
                    }
                    i {
                        padding-right: 2px;
                        color: white;
                        font-size: 18px;
                        line-height: 1;
                    }
                    a {
                        color: rgba(170, 170, 170, 1);
                        transition: 0.3s;
                        display: inline-block;
                        line-height: 1;
                        &:hover {
                            text-decoration: none;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
        }
    }
    .footer-bottom{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
        padding: 1rem 2rem;
        .footer-bottom-left{
            .credits {
                padding-top: 5px;
                font-size: 13px;
                a{
                    &:hover {
                        color: var(--oksijen-turuncu);
                        cursor: pointer;
                    }
                }
            }
            .copyright{
                a {
                    color: rgba(255, 255, 255, 1);
                    &:hover{
                        color: var(--oksijen-turuncu);
                        cursor: pointer;
                    }
                }
            }
        }
        .footer-bottom-right {
            .social-links{
                text-align: center;
                a {
                    font-size: 18px;
                    display: inline-block;
                    background: var(--oksijen-mavi-light);
                    color: rgba(255, 255, 255, 1);
                    line-height: 1;
                    padding: 8px 0;
                    margin-right: 1px;
                    border-radius: 4px;
                    text-align: center;
                    width: 36px;
                    height: 36px;
                    transition: 0.3s;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        background: var(--oksijen-turuncu);
                        color: rgba(255, 255, 255, 1);
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .map {
        width: 100% !important;
    }
}
